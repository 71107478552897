<template>
  <RevForm
    :initial-values="initialValues"
    method="POST"
    :validate="validate()"
    @submit="handleSubmit"
  >
    <template #default="{ values, errors }">
      <RevCard class="p-24">
        <div class="heading-3 mb-12 md:mb-24">
          {{ i18n(translations.billingAddressTitle) }}
        </div>
        <DynamicAddressFieldValidatorsErrorLogger
          :errors="errors"
          :form-values="values"
          logger-scope="CHECKOUT_BILLING"
        />
        <AddressFields
          v-model:city="values.city"
          v-model:company="values.company"
          v-model:country="values.country"
          v-model:first-name="values.firstName"
          v-model:first-name-pronunciation="values.firstNamePronunciation"
          v-model:last-name="values.lastName"
          v-model:last-name-pronunciation="values.lastNamePronunciation"
          v-model:postal-code="values.postalCode"
          v-model:state-or-province="values.stateOrProvince"
          v-model:street="values.street"
          v-model:street2="values.street2"
          :countries="countries"
          :default-country="countryCode"
          :errors="allErrors(errors)"
          :feature-code="FeatureCode.WEB_CHECKOUT_BILLING"
          :optional-fields="['company']"
          @autocompleted="handleAddressAutocomplete"
        />

        <div v-if="shouldDisplayUserInformation(values.country)">
          <RevDivider class="my-16 md:my-24" />
          <h2 class="heading-3 mb-16">
            {{ i18n(translations.userInformationTitle) }}
          </h2>
          <UserInformationFields
            v-model:birthdate="values.birthdate"
            v-model:national-id="values.nationalId"
            :country="values.country"
            :errors="errors"
            :with-birthdate="withUserInformation"
            :with-national-id="userInformationWithNationalId(values.country)"
          />

          <div class="mt-20 flex flex-col items-stretch md:items-end">
            <RevButton
              data-qa="submit-button"
              :disabled="submitting"
              full-width="responsive"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.submit) }}
            </RevButton>
          </div>
        </div>

        <div
          v-if="
            !withUserInformation &&
            !shouldDisplayUserInformation(values.country)
          "
          class="mt-32 flex flex-col items-stretch md:items-end"
        >
          <RevButton
            data-qa="submit-button"
            :disabled="submitting"
            full-width="responsive"
            type="submit"
            variant="primary"
          >
            {{ i18n(translations.submit) }}
          </RevButton>
        </div>
      </RevCard>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { reactive } from 'vue'

import { Country, addressAPI, cartAPI } from '@backmarket/http-api'
import AddressFields from '@backmarket/nuxt-module-address/AddressFields.vue'
import { AddressFieldsValidators } from '@backmarket/nuxt-module-address/AddressFieldsValidators'
import DynamicAddressFieldValidatorsErrorLogger from '@backmarket/nuxt-module-address/DynamicAddressFieldValidatorsErrorLogger.vue'
import { InputAddressBirthdateValidators } from '@backmarket/nuxt-module-address/InputAddressBirthdateValidators'
import UserInformationFields from '@backmarket/nuxt-module-address/UserInformationFields.vue'
import { FeatureCode } from '@backmarket/nuxt-module-address/featureCode'
import { useDynamicAddressFieldValidators } from '@backmarket/nuxt-module-address/useDynamicAddressFieldValidators'
import { isNationalIdShownForBilling } from '@backmarket/nuxt-module-address/utils'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { omit } from '@backmarket/utils/object/omit'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import {
  RevForm,
  type ValidatorDeclaration,
  makeValidate,
} from '@ds/components/Form'

import { CHECKOUT_LOG_TYPES } from '~/scopes/checkout/config'
import { useUserInformationStore } from '~/scopes/checkout/stores/userInformationStore'

import translations from './BillingAddressForm.translations'

const props = withDefaults(
  defineProps<{
    submitting?: boolean
    address: cartAPI.Bill
    apiErrors: { [key: string]: string }
    countries?: {
      value: string
      label: string
    }[]
    withUserInformation?: boolean
  }>(),
  {
    submitting: false,
    withUserInformation: false,
    countries: () => [],
  },
)
const i18n = useI18n()
const tracking = useTracking()
const {
  market: { countryCode },
} = useMarketplace()

const userInformationStore = useUserInformationStore()
const { getDynamicAddressFieldValidators } = useDynamicAddressFieldValidators()

export type FormValues = {
  city: string
  company: string
  country: Country
  firstName: string
  lastName: string
  nationalId?: string
  postalCode: string
  stateOrProvince: string
  street: string
  street2: string
  birthdate?: string
  firstNamePronunciation?: string
  lastNamePronunciation?: string
}

const initialValues = reactive<FormValues>({
  city: props.address.city ?? '',
  company: props.address.company ?? '',
  country: (props.address.country as Country) ?? '',
  firstName: props.address.firstName ?? '',
  lastName: props.address.lastName ?? '',
  nationalId: props.address.customerIdNumber ?? '',
  postalCode: props.address.postalCode ?? '',
  stateOrProvince: props.address.stateOrProvince ?? '',
  street: props.address.street ?? '',
  street2: props.address.street2 ?? '',
  ...insertIf(userInformationStore.isFormRequired, {
    birthdate: props.address.birthdate ?? '',
  }),
  ...insertIf(props.address.country === Country.JP, {
    firstNamePronunciation: props.address.firstNamePronunciation ?? '',
    lastNamePronunciation: props.address.lastNamePronunciation ?? '',
  }),
})

const emit = defineEmits<{ submit: [values: FormValues] }>()

// Need to fix nationalId validation
const fallbackValidators: ValidatorDeclaration<addressAPI.AddressFieldsValues> =
  {
    ...AddressFieldsValidators(i18n),
    ...insertIf(userInformationStore.isFormRequired, {
      birthdate: InputAddressBirthdateValidators(i18n),
      // nationalId: InputAddressNationalIdValidators(i18n),
    }),
  }

const addressValidation = await getDynamicAddressFieldValidators({
  featureFlag: 'FF_ENABLE_ADDRESS_FIELD_VALIDATION_CHECKOUT_BILLING',
  initialFormValues: initialValues,
  fallbackValidators,
  metadata: {
    scope: 'customer',
  },
})

function validate() {
  return makeValidate<FormValues>(addressValidation)
}

const allErrors = (errors: Partial<Record<keyof FormValues, string>>) => {
  return { ...errors, ...props.apiErrors }
}

const userInformationWithNationalId = (selectedCountry: Country) => {
  return isNationalIdShownForBilling(selectedCountry)
}

const handleSubmit = (values: FormValues) => {
  emit('submit', {
    ...omit(values, 'nationalId'),
    ...insertIf(
      isNationalIdShownForBilling(values.country) &&
        !isEmpty(values.nationalId),
      {
        customerIdNumber: values.nationalId,
      },
    ),
  })
}

const handleAddressAutocomplete = (
  address: { [key: string]: string },
  index: number,
) => {
  tracking.trackChange({
    name: '[CHECKOUT] Address autocomplete',
    optionIndex: index,
    address,
    isShipping: false,
    isBilling: true,
    isCollectionPoint: false,
    type: CHECKOUT_LOG_TYPES.ADDRESS_AUTOCOMPLETE,
  })
}

const shouldDisplayUserInformation = (country: Country) => {
  return props.withUserInformation || userInformationWithNationalId(country)
}
</script>
